'use client'
 
import { useReportWebVitals } from 'next/web-vitals'
 
export function WebVitals() {
    useReportWebVitals((metric) => {
        // Check for specific metric names and send them to GTM
        const validMetrics = ['LCP', 'FCP', 'CLS', 'TTFB', 'INP'];
        
        if (validMetrics.includes(metric.name)) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "web_vitals",
            metric_name: metric.name,
            value: Math.round(metric.name === "CLS" ? metric.value * 1000 : metric.value),
            metric_id: metric.id,
            non_interaction: true,  
          });
        }
      });
 
  return null
}