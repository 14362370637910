"use client";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const RecaptchaV3Provider = ({ children }) => {
  return (
    <GoogleReCaptchaProvider
      useEnterprise
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY}
      scriptProps={{
        async: true,
        appendTo: "body",
        id: "google-reCaptcha",
      }}
      container={{
        parameters: {
          badge: "bottomleft",
        },
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default RecaptchaV3Provider;
