"use client";

import StoreProvider from "./StoreProvider";
import RecaptchaV3Provider from "./RecaptchaV3Provider";
import { AppInitialization } from "./AppInitialization";

const Providers = ({ children }) => {
  return (
    <StoreProvider>
      <RecaptchaV3Provider>
        <AppInitialization>{children}</AppInitialization>
      </RecaptchaV3Provider>
    </StoreProvider>
  );
};

export default Providers;
